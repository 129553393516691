<template>
  <div>
    <section class="main services">
      <div class="hero parallax"></div>

      <h2>{{ currentRouteName }}</h2>

      <article>
        <div class="thumb"></div>
        <p class="intro">
          There can be no greater responsibility than providing a safe and secure environment for educational institutions and establishments protecting its students, staff and visitors as well as its real and personal property. According to the FBI recent reports, mass shooting at schools became more frequent in the past two years, and it raises huge concerns that shall be addressed. To secure educational establishments, we are hiring well-trained security guards who passed background checks at state and FBI levels.<br /><br />

          We understand that each campus faces unique challenges. Having years of experiences in providing security services for educational establishments, we are willing to limit your vulnerability to risks assuring that your learning environment is safe and secure<br /><br />

          GammaTeam Security specializes in providing security solutions for school, college and university campuses.
          Our trained and certified <strong><router-link to="/services">security officers</router-link></strong> will:
        </p>
        <ul>
          <li v-for="(item, i) in tasks" :key="i">{{ item }}</li>
        </ul>
      </article>

      <call-to-action :link_back=true></call-to-action>
    </section>
  </div>
</template>

<script>
export default {
  name: 'EducationSecurity',
  data: () => ({
    tasks: ['Perform foot / Vehicle patrol of the interior and exterior premises on a scheduled interval to detect suspicious behaviors', 'Monitor using surveillance systems', 'Secure buildings and property', 'Investigate disturbances', 'Maintain order during events', 'Accompany personnel when responding to an accident or a problem', 'Enforce regulations and policies', 'Enforce traffic and parking regulations where necessary']
  })
}
</script>

<style scoped>
section.main article div.thumb {
  background-image: url("/images/services/education.jpg");
}
</style>
